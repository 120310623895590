/* global window, document, HTMLElement, customElements */
import { AIDElement } from '../../common/element';

class AidTextInput extends AIDElement {
    connectedCallback() {
        this.isRendered();
    }

    addShowPasswordButton() {
        if (!this.toggle) {
            this.toggle = document.createElement('button');
            this.toggle.type = 'button';
            this.toggle.classList.add('aid-text-input--passwordButton');
            this.toggle.textContent = 'Vis';
            this.toggle.addEventListener('click', () => this.toggleShowHidePassword());
            this.appendChild(this.toggle);
        }
    }

    toggleShowHidePassword() {
        this.input.type = this.input.type === 'text' ? 'password' : 'text';
        this.input.textContent = this.input.type === 'text' ? 'Skjul' : 'Vis';
        this.input.focus();
    }

    childrenParsedCallback() {
        this.input = this.querySelector('input');

        if (this.input.type === 'password') {
            this.addShowPasswordButton();
        }
    }
}

if (!customElements.get('aid-text-input')) {
    customElements.define('aid-text-input', AidTextInput);
}
